import { arrayIncludes } from '@digital-magic/ts-common-utils'
import { languageConfiguration } from '@constants/configuration'
import { Language, TranslatedString } from '@api/endpoints/types'
import { TranslatedFile, TranslatedFileId, TranslatedFileList } from '@api/endpoints/files'

export type AvailableLanguage = (typeof languageConfiguration.availableLanguages)[number]

export const language = (lang: string): Language => {
  if (arrayIncludes(languageConfiguration.availableLanguages, lang)) {
    switch (lang) {
      case 'en':
        return Language.enum.ENG
      case 'ru':
        return Language.enum.RUS
      default:
        return Language.enum.EST
    }
  } else {
    // This code ensures that english is present in both availableLanguage & defaultLanguage configurations
    const en: AvailableLanguage = 'en'
    if (!languageConfiguration.defaultLanguage.includes(en)) {
      // eslint-disable-next-line no-console
      console.error(
        'Fallback language is not defined in languages list for i18n configuration: ',
        languageConfiguration.defaultLanguage
      )
    }
    return Language.enum.ENG
  }
}

type TranslatedObject = TranslatedString | TranslatedFileId | TranslatedFile | TranslatedFileList

export const getTranslatedField = (lang: Language): keyof TranslatedObject => {
  switch (lang) {
    case Language.enum.ENG:
      return 'eng'
    case Language.enum.EST:
      return 'est'
    case Language.enum.RUS:
      return 'rus'
  }
}
